
import "./App.css";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Loader,google } from "@googlemaps/js-api-loader"
import React, { useState, useEffect } from "react";
import "./styles.css";
import CashFlowModelTable from './cashFlow.js';
import Currency from 'react-currency-formatter';
var CurrencyFormat = require('react-currency-format');

function calcDistance(lat1, lon1, lat2, lon2) 
    {
      var R = 6371; // km
      var dLat = toRad(lat2-lat1);
      var dLon = toRad(lon2-lon1);
      var lat1 = toRad(lat1);
      var lat2 = toRad(lat2);

      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c;
      return d;
    }

    // Converts numeric degrees to radians
    function toRad(Value) 
    {
        return Value * Math.PI / 180;
    }

    function kmToMiles(km) {
      return km * 0.621371;
    }
    

function App() {
  
  let [error, setError] = useState("");
  let [address, setAddress] = useState("");
  let [listing, setListing] = useState([]);
  let [listingAvg, setListingAvg] = useState([]);
  let [loading, setLoading] = useState(false);
  let [listingOrignal, setListingOrignal] = useState([]);
  let [aiDesc, setAiDesc] = useState([]);
  let [images, setImages] = useState([]);
  let [zestimateRent, setZestimateRent] = useState({});
  let [leaseComps, setLeaseComps] = useState({});
  let [leaseCompsAvg, setLeaseCompsAvg] = useState({});


  var loadList = async () => {
    if (!address) {
      alert("Please enter an address");
      return;
    }
    // setListing(dt.props);
    console.log(address);
    setError("");
    {/*setLoading(true);*/}
    setLoading(true);
    setListing([]);
    setListingOrignal([]);
    setListingAvg([]);
    setAiDesc([]);
    setImages([]);
    setZestimateRent([]);
    setLeaseComps([]);
    setLeaseCompsAvg([]);
    
    // setListing(a.ad1);
    // setListingOrignal(a.ad);
    // aler
    // return;
    fetch("/api?address=" + address.label)
      .then(res => res.json())
      .then(
        (result) => {
          setLoading(false);
          if (result.error) {
            setError("Unable to process this specific address, please check address or enter new address"); // Set error message when error occurs
          }else{
            console.log(result);
            setListing(result.saleComps);
            setLeaseComps(result.leaseComps);
            setListingOrignal(result.propDetails);
            setAiDesc(result.aiDesc);
            setImages(result.images);
            setZestimateRent(result.zestimateRent);
            var price = 0;
            var sf = 0;
            var psf = 0;
            var bedrooms = 0;
            var bathrooms = 0;
            var validSaleCount = 0;


            var leasePrice = 0;
            var leaseSf = 0;
            var leasePsf = 0;
            var leaseBedrooms = 0;
            var leaseBathrooms = 0;
            var validLeaseCount = 0;


            result.saleComps.slice(0, 10).forEach(item => {
              if (item.price && item.livingArea) {
                price += item.price;
                sf += item.livingArea;
                psf += (parseFloat(item.price) / parseFloat(item.livingArea));
                bedrooms += item.bedrooms;
                bathrooms += item.bathrooms;
                validSaleCount++;
              }
            });
            
            setListingAvg([{
              price: (price / validSaleCount),
              sf: (sf / validSaleCount),
              psf: (psf / validSaleCount).toFixed(2),
              bedrooms: (bedrooms / validSaleCount),
              bathrooms: (bathrooms / validSaleCount)
            }]);

            result.leaseComps.slice(0, 10).forEach(item => {
              if (item.price && item.livingArea) {
                leasePrice += item.price;
                leaseSf += item.livingArea;
                leasePsf += (parseFloat(item.price) / parseFloat(item.livingArea));
                leaseBedrooms += item.bedrooms;
                leaseBathrooms += item.bathrooms;
                validLeaseCount++;
              }
            });
            
            setLeaseCompsAvg([{
              leasePrice: (leasePrice / validLeaseCount),
              leaseSf: (leaseSf / validLeaseCount),
              leasePsf: (leasePsf / validLeaseCount).toFixed(2),
              leaseBedrooms: (leaseBedrooms / validLeaseCount),
              leaseBathrooms: (leaseBathrooms / validLeaseCount)
            }]);
          }

        },
      )
        // Clear the address input field after the deal is run
  setAddress(null);
  };
 
// event handler for the address input field
const handleAddressClick = () => {
  address.value = null;
  setAddress(address);
}

const calculateAverage = (...values) => {
  const validValues = values.filter((value) => typeof value === "number" && !isNaN(value));
  const sum = validValues.reduce((acc, value) => acc + value, 0);
  return sum / validValues.length;
};

  // Calculate leaseAverage just before the return statement.
  let averageRentalRate = calculateAverage(
    listingOrignal.rentZestimate,
    zestimateRent.median,
    leaseCompsAvg[0]?.leasePsf * listingOrignal.livingArea
  );
  
  return (
    <div className="App">
      <div className="App-header container">
        <div className="ctn-gtxt no-print">
        <GooglePlacesAutocomplete
  id="txt-address"
  apiKey="AIzaSyCr4jQkaPE_y9h-RYtkP8xs4ADLMa0xS4E"
  selectProps={{
    address,
    onChange: (selectedAddress) => {
      // Remove ", USA" from the selected address label
      const addressWithoutCountry = {
        ...selectedAddress,
        label: selectedAddress.label.replace(/, USA$/, ''),
      };

      // Call setAddress with the updated address
      setAddress(addressWithoutCountry);
    },
    noOptionsMessage: () => null, // Prevent "No options" message and dropdown
    placeholder: address ? address.label : 'Enter address', // Change the placeholder text
    onClick: handleAddressClick
    // Set value to null when address state is null and dealLoaded is true, so that the placeholder text will be shown
    // Otherwise, use the address value from state
  }}
  autocompletionRequest={{
    componentRestrictions: {
      country: 'us',
    },
    types: ['geocode'],
  }}
/>

        </div>
        
        <br/>
        <button className="btn btn-size no-print" onClick={() => loadList()}>Size Deal</button>
        <br />
        {error ? ( // Show error message if it exists
          <span className="error">{error}</span>
        ) : loading ? ( // Show "Analyzing" if not error and loading
          <span className="ellipsis">Analyzing</span>
        ) : (
          ""
        )}



{/* this header is to display the address when printing, hidden otherwise*/}
<div class="print-header">
    {listingOrignal.streetAddress}
</div>

        {listingOrignal.length <= 0 ? " ":  (
          <div className="row page1 page1-print">
            {/* Property Details*/} 
          <div className="col-lg-6">
          <div className="list-ctn card bl mt-5">
          <div className="card-body">
              <div className="card-title">
                <h4 className="h4-custom-bg">Property Details:</h4>
              </div>
              <table className="table table-borderless table-responsive">
                <tbody>
                  <tr className="litem">
                    <th>Address</th>
                    <td style={{textAlign: 'left'}}>{listingOrignal.streetAddress}</td>
                  </tr>
                  <tr className="litem">
                    <th>Square Footage</th>
                    <td style={{textAlign: 'left'}}>{listingOrignal.livingArea.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})} sqft</td>
                  </tr>
                  <tr className="litem">
                    <th>Lot Size</th>
                    <td style={{textAlign: 'left'}}>
                      {listingOrignal.lotSize}
                    </td>
                  </tr>
                  <tr className="litem">
                    <th>Bedrooms</th>
                    <td style={{textAlign: 'left'}}>
                      {listingOrignal.bedrooms}
                    </td>
                  </tr>
                  <tr className="litem">
                    <th>Bathrooms</th>
                    <td style={{textAlign: 'left'}}>
                      {listingOrignal.bathrooms}
                    </td>
                  </tr>
                  <tr className="litem">
                    <th>Year Built</th>
                    <td style={{textAlign: 'left'}}>
                      {listingOrignal.yearBuilt}
                    </td>
                  </tr>
                  <tr className="litem">
                    <th>HOA Fee</th>
                    <td style={{textAlign: 'left'}}>
                      {listingOrignal.monthlyHoaFee}
                    </td>
                  </tr>
                  <tr className="litem" style={{border : 0}}>
                    <th>Home Type</th>
                    <td style={{textAlign: 'left'}}>
                      {listingOrignal.propertyTypeDimension}
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
          </div>

{/* Property Valuation*/} 
<div className="list-ctn card bl mt-3">
          <div className="card-body">
              <div className="card-title">
                <h4 className="h4-custom-bg">Property Valuation:</h4>
              </div>
              <table className="table table-borderless table-responsive">
                <thead>
                  <tr className="litem">
                    <th style={{backgroundColor: '#f0f0f0', }}>Scenario</th>
                    <td style={{backgroundColor: '#f0f0f0', }}><strong>Valuation</strong></td>
                    <td style={{backgroundColor: '#f0f0f0', }}><strong>$/SF</strong></td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="litem">
                    <th>Sales Comparables - Most Similar</th>
                    <td>
                      <CurrencyFormat
                        value={listingAvg[0].psf * listingOrignal.livingArea}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={0}
                        renderText={value => <div>{value}</div>}
                      />
                    </td>
                    <td>
                    ${listingAvg[0].psf.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}
                    </td>
                  </tr>
                  {/*<tr className="litem">
                    <th>Sale Comparables - Last 3mo.</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="litem">
                    <th>Sale Comparables - Last 6mo.</th>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="litem">
                    <th>Sale Comparables - Last 12mo.</th>
                    <td></td>
                    <td></td>
                    </tr>*/}
                  <tr className="litem">
                    <th>Zestimate Valuation</th>
                    <td>
                      {listingOrignal.zestimate?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }) ?? "N/A"}
                    </td>
                    <td>
                    {(listingOrignal.zestimate === null || listingOrignal.zestimate === "null")
                      ? "N/A"
                      : <CurrencyFormat
                          value={listingOrignal.zestimate / listingOrignal.livingArea}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          renderText={value => <div>{value}</div>}
                        />}
                  </td>
                  </tr>
                  <tr className="litem" style={{border : 0}}>
                    <th>Most Recent Listing Price</th>
                    <td>
                      {listingOrignal.price?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }) ?? "N/A"}
                    </td>
                    <td>
                    {(listingOrignal.price === null || listingOrignal.price === "null")
                      ? "N/A"
                      : <CurrencyFormat
                          value={listingOrignal.price / listingOrignal.livingArea}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          renderText={value => <div>{value}</div>}
                        />}
                  </td>
                  </tr>
                </tbody>
              </table>
              </div>
          </div>






        {/* Financial Returns Summary*/} 

        {/*<div className="list-ctn card bl mt-3">
        <div className="list card-body">
        <div className="card-title">
          <h4>Financial Returns:</h4>
        </div>
          <div className="row">
            <div className="litem ">
              <span className="lb col-6">Cash Return</span>
              <span className="txt col-6">{}</span>
            </div>
            <div className="litem">
              <span className="lb col-6">Cash on Cash Return / Equity Multiple</span>
              <span className="txt col-6">{}</span>
            </div>
            <div className="litem">
              <span className="lb col-6">Internal Rate of Return</span>
              <span className="txt col-6">{}</span>
            </div>
            <div className="litem">
              <span className="lb col-6">Net Present Value - 5 Year</span>
              <span className="txt col-6">{}</span>
            </div>              
            </div>
            </div>
          </div> */}


        {/* Rental Income Summary*/}

<div className="list-ctn card bl mt-3">
          <div className="card-body">
              <div className="card-title">
                <h4 className="h4-custom-bg">Rental Income Summary:</h4>
              </div>
              <table className="table table-borderless table-responsive">
                <thead>
                  <tr>
                    <th style={{backgroundColor: '#f0f0f0', }}>Scenario</th>
                    <td style={{backgroundColor: '#f0f0f0', }}><strong>Lease $/mo</strong></td>
                    <td style={{backgroundColor: '#f0f0f0', }}><strong>Lease $/sf</strong></td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="litem">
                    <th>Lease Comps - Most Similar</th>
                    <td>
                    {leaseCompsAvg[0]?.leasePsf && listingOrignal?.livingArea ? (
                      <CurrencyFormat
                        value={leaseCompsAvg[0].leasePsf * listingOrignal.livingArea}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        renderText={value => <div>{value}</div>}
                      />
                    ) : (
                      "N/A"
                    )}
                  </td>
                    <td>
                    {leaseCompsAvg[0].leasePsf?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }) ?? "N/A"}
                    </td>
                  </tr>
                  <tr className="litem">
                    <th>Market Comps - Median Rent</th>
                    <td>
                      {zestimateRent.median?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }) ?? "N/A"}
                    </td>
                    <td>
                    {(zestimateRent.median === null || zestimateRent.median === "null")
                      ? "N/A"
                      : <CurrencyFormat
                          value={zestimateRent.median / listingOrignal.livingArea}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          renderText={value => <div>{value}</div>}
                        />}
                    </td>
                  </tr>
                  <tr className="litem">
                    <th>Zillow Rent Estimate</th>
                    <td>
                      {listingOrignal.rentZestimate?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }) ?? "N/A"}
                    </td>
                    <td>
                    {(listingOrignal.rentZestimate === null || listingOrignal.rentZestimate === "null")
                      ? "N/A"
                      : <CurrencyFormat
                          value={listingOrignal.rentZestimate / listingOrignal.livingArea}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          renderText={value => <div>{value}</div>}
                        />}
                    </td>
                  </tr>
                  <tr className="litem" style={{border : 0}}>
                    <th>Average Rental Rate (All Data)</th>
                    <td>
                      {averageRentalRate?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }) ?? "N/A"}
                    </td>
                    <td>
                    {(averageRentalRate === null || averageRentalRate === "null")
                      ? "N/A"
                      : <CurrencyFormat
                          value={averageRentalRate / listingOrignal.livingArea}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          renderText={value => <div>{value}</div>}
                        />}
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
          </div>
          </div>

          
          {/* Property photos */}
          <div className="col-lg-6">
          <div className="photos-print">
          {listing.length <= 0 ? "" : (
            <div className="mt-5 property-photos-container">
              <div className="mk">Property Photos</div>
              <div className="property-images image-row">
                {images.slice(0, 20).map((imageUrl, index) => (
                  <img
                    key={index}
                    src={imageUrl}
                    alt={`Property Image ${index + 1}`}
                    className="property-image"
                    onClick={() => window.open(imageUrl, "_blank")}
                    style={{ cursor: "pointer" }}
                  />
                ))}
              </div>
            </div>
          )}
</div>
</div>
</div>  
)}  


{/* Narrative Write-Up*/}
{listing.length <= 0 ? "" :
  <div className="break-before">
    <div className="descr print-black-text">
      <div className="mt-5">
        {/*<h4 className="h4-custom-bg">A.I. Narrative</h4>
        <p>
          {aiDesc}
        </p>
      </div>*/}
      <h4 className="h4-custom-bg">Property Description</h4>
      <p>
        {listingOrignal.description}
      </p>
    </div>
    </div>
  </div>
}
               

        

        {/* Sale Comps*/} 
        <div className="break-before cash-flow-print">
        <div className="mt-5">
        {listing.length <= 0 ? "" : <div className="mk">Market Sale Comps</div> }
        <div className="table-container">
        {listing.length <= 0 ? "" : <table className="list-ctnComps">
        <thead>
        <tr>
          <th>Comp#</th>
          <th>Address</th>
          <th>Sale Price</th>
          <th>Date Sold</th>
          <th>Rentable Living (SF)</th>
          <th>$/SF</th>
          <th>Bedrooms</th>
          <th>Bathrooms</th>
          <th>Property Type</th>
          <th>Distance</th>
          <th>Data Source</th>
          <th>Image</th>
        </tr>
      </thead>
      <tbody>
      <tr style={{"backgroundColor": "rgba(230, 247, 247, 0.3)" }}>
          <td><b>Subject property</b></td>
          <td>{listingOrignal.streetAddress}</td>
          <td>-</td>
          <td>-</td>
          <td>{listingOrignal.livingArea.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
          <td>-</td>
          <td>{listingOrignal.bedrooms}</td>
          <td>{listingOrignal.bathrooms}</td>
          <td style={{fontSize : "12px"}}>{listingOrignal.propertyTypeDimension}</td>
          <td>-</td>
          <td>-</td>
          <td>
  <img
    src={listingOrignal.imgSrc}
    style={{ maxHeight: "75px", width: "auto" }}
    onError={(e) => { e.target.src = "noimg.jpeg"; }}
  />
</td>
        </tr>
        {listing.slice(0, 10).map((item, k) => (
          <tr key={k}>
            <td><b>{k + 1}</b></td>
            <td>{item["address"]}</td>
            <td>{item["price"].toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })}</td>
            <td>{item.variableData.text ? item.variableData.text : '-'}</td>
            <td>{item["livingArea"].toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
            <td><Currency quantity={(parseFloat(item["price"]) / parseFloat(item["livingArea"]))} /></td>
            <td>{item["bedrooms"]}</td>
            <td>{item["bathrooms"]}</td>
            <td style={{fontSize : "12px"}}>{item["propertyType"]}</td>
            <td>{ item.latitude != null ? parseFloat(kmToMiles(calcDistance(listingOrignal.latitude,listingOrignal.longitude, item.latitude,item.longitude ))).toFixed(2) + " mi" : "-"}</td>
            <td>-</td>
            <td>
            <img
              alt=""
              src={item["imgSrc"] ? item["imgSrc"] : "/noimg.png"}
              style={{ maxHeight: "75px", width: "auto", cursor: "pointer" }}
              onClick={() => window.open(item["imgSrc"] ? item["imgSrc"] : "/noimg.png", "_blank")}
              onError={(e) => { e.target.src = "noimg.jpeg"; }}
            />
          </td>
          </tr>
          ))}
          {listingAvg.length > 0 && (
          <tr style={{"fontWeight": "bold", "backgroundColor": "#f2f2f2"}}>
          <td>Averages</td>
          <td>-</td>
          <td>{listingAvg[0].price.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })}</td>
          <td>-</td>
          <td>{listingAvg[0].sf.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
          <td>${listingAvg[0].psf.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
          <td>{listingAvg[0].bedrooms}</td>
          <td>{listingAvg[0].bathrooms}</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>
          <img src="/noimg.png" width="100%" style={{"opacity" : "0"}} alt=""/>
          </td>
          </tr>
          )}
          </tbody>
          </table>}
            </div>
            </div>
            </div>
            

          {/* Lease Comps*/} 
          <div className="break-before cash-flow-print">
          <div className="mt-5">
          {listing.length <= 0 ? "" : <div className="mk">Market Lease Comps</div> }
          <div className="table-container">
              {listing.length <= 0 ? "" : <table className="list-ctnComps">
                <thead>
                  <tr>
                    <th>Comp#</th>
                    <th>Address</th>
                    <th>Lease Amount</th>
                    <th>Rentable Living (SF)</th>
                    <th>$/SF</th>
                    <th>Bedrooms</th>
                    <th>Bathrooms</th>
                    <th>Property Type</th>
                    <th>Distance</th>
                    <th>Data Source</th>
                    <th>Image</th>
                  </tr>
                </thead>
                <tbody>
                <tr style={{"backgroundColor": "rgba(230, 247, 247, 0.3)" }}>
                    <td><b>Subject property</b></td>
                    <td>{listingOrignal.streetAddress}</td>
                    <td>-</td>
                    <td>{listingOrignal.livingArea.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
                    <td>-</td>
                    <td>{listingOrignal.bedrooms}</td>
                    <td>{listingOrignal.bathrooms}</td>
                    <td style={{fontSize : "12px"}}>{listingOrignal.propertyTypeDimension}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      <img
                        src={listingOrignal.imgSrc}
                        style={{ maxHeight: "75px", width: "auto" }}
                        onError={(e) => { e.target.src = "noimg.jpeg"; }}
                      />
                    </td>
                  </tr>
                  {leaseComps.slice(0, 10).map((item, k) => (
                    <tr key={k}>
                      <td><b>{k + 1}</b></td>
                      <td>{item["address"]}</td>
                      <td>{item["price"].toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })}</td>
                      <td>{item["livingArea"].toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
                      <td><Currency quantity={(parseFloat(item["price"]) / parseFloat(item["livingArea"]))} /></td>
                      <td>{item["bedrooms"]}</td>
                      <td>{item["bathrooms"]}</td>
                      <td style={{fontSize : "12px"}}>{item["propertyType"]}</td>
                      <td>{ item.latitude != null ? parseFloat(kmToMiles(calcDistance(listingOrignal.latitude,listingOrignal.longitude, item.latitude,item.longitude ))).toFixed(2) + " mi" : "-"}</td>
                      <td>-</td>
                      <td>
                        <img
                          alt=""
                          src={item["imgSrc"] ? item["imgSrc"] : "/noimg.png"}
                          style={{ maxHeight: "75px", width: "auto", cursor: "pointer" }}
                          onClick={() => window.open(item["imgSrc"] ? item["imgSrc"] : "/noimg.png", "_blank")}
                          onError={(e) => { e.target.src = "noimg.jpeg"; }}
                        />
                      </td>
                    </tr>
                  ))}
                  {leaseCompsAvg.length > 0 && (
                    <tr style={{ "fontWeight": "bold", "backgroundColor": "#f2f2f2" }}>
                    <td>Averages</td>
                    <td>-</td>
                    <td>{leaseCompsAvg[0].leasePrice.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })}</td>
                    <td>{leaseCompsAvg[0].leaseSf.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
                    <td>${leaseCompsAvg[0].leasePsf.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    <td>{leaseCompsAvg[0].leaseBedrooms}</td>
                    <td>{leaseCompsAvg[0].leaseBathrooms}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      <img src="/noimg.png" width="100%" style={{ "opacity": "0" }} alt="" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>}
          </div>
          </div>
          </div>

{/*Cashflow Table*/}
    <div className="break-before cash-flow-print">
    {listing.length <= 0 ? "" : <div className="mt-5 cashFlow-container">
    <div className="mk">Cash Flow Model</div>
    <div className="list-ctnComps">
        <CashFlowModelTable
        rentZestimate={listingOrignal.rentZestimate}
        medianMarketRentalRate={zestimateRent.median}
        salePrice={listingOrignal.price}
        leaseCompsAvg={leaseCompsAvg[0].leasePsf * listingOrignal.livingArea}
        />
      </div>
      </div>}
      </div>
       
      </div>
      </div>
  

  );
}

export default App;

import "./styles.css";
import { useState, useEffect } from "react";



const CashFlowModelTable = ({ 
  rentZestimate,
  medianMarketRentalRate,
  salePrice,
  leaseCompsAvg,
}) => {
 const initialValues = {
   purchasePrice: salePrice,
   downPayment: 20,
   interestRate: 6.5,
   closingCosts: 2.5,
   loanDuration: 30,
   utilities: 100,
   propertyTaxes: 1.17,
   homeInsurance: 0.22,
 };

 const calculateAverage = (...values) => {
  const validValues = values.filter((value) => typeof value === "number" && !isNaN(value));
  const sum = validValues.reduce((acc, value) => acc + value, 0);
  return sum / validValues.length;
};

// Update the averageRentalRate calculation to use the new function
const averageRentalRate = calculateAverage(
  rentZestimate,
  medianMarketRentalRate,
  leaseCompsAvg,
);

 const initialData = [
  { name: "Lease Comps - Most Similar", input: { ...initialValues, rentalRevenue: leaseCompsAvg }, value: "" },
  { name: "Market Comps - Median Rent", input: { ...initialValues, rentalRevenue: medianMarketRentalRate }, value: "" },
  { name: "Zillow Rent Estimate", input: { ...initialValues, rentalRevenue: rentZestimate }, value: "" },
  //{ name: "Short-Term Rental Rate", input: { ...initialValues, rentalRevenue: 6000 }, value: "" },
  { name: "Average Rental Rate (All Data)", input: { ...initialValues, rentalRevenue: averageRentalRate }, value: "" },
];



 const [scenarioData, setScenarioData] = useState(initialData);
 const [purchasePrice, setPurchasePrice] = useState(initialValues.purchasePrice);
 const [downPayment, setDownPayment] = useState(initialValues.downPayment);
 const [interestRate, setInterestRate] = useState(initialValues.interestRate);
 const [closingCosts, setClosingCosts] = useState(initialValues.closingCosts);
 const [loanDuration, setLoanDuration] = useState(initialValues.loanDuration);
 const [utilities, setUtilities] = useState(initialValues.utilities);
 const [propertyTaxes, setPropertyTaxes] = useState(initialValues.propertyTaxes);
 const [homeInsurance, setHomeInsurance] = useState(initialValues.homeInsurance);
 const [rentalRevenue, setRentalRevenue] = useState(initialValues.rentalRevenue);

 useEffect(() => {
   const updateData = (key, value) => {
     setScenarioData((prevData) =>
       prevData.map((scenario) => {
         return { ...scenario, input: { ...scenario.input, [key]: value } };
       })
     );
   };


   updateData("purchasePrice", purchasePrice);
   updateData("downPayment", downPayment);
   updateData("interestRate", interestRate);
   updateData("closingCosts", closingCosts);
   updateData("loanDuration", loanDuration);
   updateData("utilities", utilities);
   updateData("propertyTaxes", propertyTaxes);
   updateData("homeInsurance", homeInsurance);
 }, [
   purchasePrice,
   downPayment,
   interestRate,
   closingCosts,
   loanDuration,
   utilities,
   propertyTaxes,
   homeInsurance,
 ]);




const calculateValues = (input) => {
  const {
    purchasePrice,
    downPayment,
    interestRate,
    closingCosts,
    loanDuration,
    rentalRevenue,
    utilities,
    propertyTaxes,
    homeInsurance,
  } = input;




  const downPaymentAmount = (purchasePrice * downPayment) / 100;
  const loanAmount = purchasePrice - downPaymentAmount;
  const loanPercent = 100 - downPayment;
  const monthlyInterestRate = (interestRate / 100) / 12;
  const numberOfPayments = loanDuration * 12;
  const monthlyLoanPayment =
    loanAmount *
    (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
    (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
  const closingCostsAmount = (purchasePrice * closingCosts) / 100;
  const totalDownPaymentClosingCosts = downPaymentAmount + closingCostsAmount;
  const propertyTaxesAmount = (purchasePrice * propertyTaxes) / 1200;
  const homeInsuranceAmount = (purchasePrice * homeInsurance) / 1200;
  const totalMonthlyExpenses =
    parseFloat(utilities) +
    propertyTaxesAmount +
    homeInsuranceAmount +
    monthlyLoanPayment;
  const monthlyNetIncome = rentalRevenue - totalMonthlyExpenses;
  const annualNetIncome = monthlyNetIncome * 12;




  return {
    downPaymentAmount,
    loanAmount,
    loanPercent,
    monthlyLoanPayment,
    closingCostsAmount,
    totalDownPaymentClosingCosts,
    propertyTaxesAmount,
    homeInsuranceAmount,
    totalMonthlyExpenses,
    monthlyNetIncome,
    annualNetIncome,
  };


};





const formatCurrency = (number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Math.round(number));
};




const formatPercent = (number) => {
  if (typeof number === 'number' && !isNaN(number)) {
    return `${number.toFixed(2)}%`;
  } else {
    return '';
  }
};




const formatValue = (value, inputType) => {
  if (value === undefined || isNaN(value)) return "";
  if (inputType === "dollar") {
    return formatCurrency(value);
  } else if (inputType === "percent") {
    return formatPercent(value);
  } else {
    return value;
  }
};
 const parseValue = (value, inputType) => {
  if (inputType === "dollar" || inputType === "percent") {
    return parseFloat(value.replace(/[^0-9.]/g, ""));
  } else {
    return parseFloat(value);
  }
};




const renderRow = (
 label,
 valueAccessor,
 isCalculated,
 inputType = null,
 calculatedValueKey = null
) => {
  const handleBlurOrKeydown = (e, valueSetter, inputType) => {
    if (e.type === "blur" || (e.type === "keydown" && (e.key === "Enter" || e.key === "Tab" || e.key === "ArrowUp" || e.key === "ArrowDown"))) {
      // Prevent form submission when pressing "Enter"
      e.preventDefault();
  
      // Update the input value
      const parsedValue = parseValue(e.target.value, inputType);
      valueSetter(parsedValue);
      e.target.value = formatValue(parsedValue, inputType);
  
      // Move to the next or previous input field when pressing "Enter", "Tab", "ArrowUp", or "ArrowDown"
      if (e.key === "Enter" || e.key === "Tab" || e.key === "ArrowUp" || e.key === "ArrowDown") {
        const inputs = Array.from(document.querySelectorAll("input"));
        const currentIndex = inputs.indexOf(e.target);
        let targetInput;
  
        if (e.key === "Enter" || e.key === "Tab" || e.key === "ArrowDown") {
          targetInput = inputs[currentIndex + 1];
        } else if (e.key === "ArrowUp") {
          targetInput = inputs[currentIndex - 1];
        }
  
        if (targetInput) {
          targetInput.focus();
          // Automatically enter an edit state for the target input field
          targetInput.select();
        }
      }
    }
  };
  
   const valueSetterMap = {
     purchasePrice: setPurchasePrice,
     downPayment: setDownPayment,
     interestRate: setInterestRate,
     closingCosts: setClosingCosts,
     loanDuration: setLoanDuration,
     utilities: setUtilities,
     propertyTaxes: setPropertyTaxes,
     homeInsurance: setHomeInsurance,
     rentalRevenue: setRentalRevenue,
   };
  
   return (
    <tr>
      <td>{label}</td>
      <td>
        {inputType ? (
          <input
            type="text"
            defaultValue={formatValue(initialValues[valueAccessor], inputType)} 
            onBlur={(e) => handleBlurOrKeydown(e, valueSetterMap[valueAccessor], inputType)}
            onKeyDown={(e) => handleBlurOrKeydown(e, valueSetterMap[valueAccessor], inputType)}
          />
        ) : null}
       </td>
       {scenarioData.map((scenario, index) => {
         const calculatedValues = isCalculated ? calculateValues(scenario.input) : {};
         let valueToDisplay;
         if (calculatedValueKey) {
           valueToDisplay = formatCurrency(calculatedValues[calculatedValueKey]);
         } else if (isCalculated) {
           valueToDisplay = formatCurrency(calculatedValues[valueAccessor]);
         } else {
           valueToDisplay =
             valueAccessor === "rentalRevenue"
               ? formatCurrency(scenario.input[valueAccessor])
               : valueAccessor === "loanDuration"
               ? scenario.input[valueAccessor]
               : formatValue(scenario.input[valueAccessor], inputType);
         }
         return <td key={`${scenario.name}-value`}>{valueToDisplay}</td>;
       })}
     </tr>
   );   
};




return (
<table className="cash-flow-model-table">
<thead>
<tr>
<th></th>
<th className="input-header">Input</th>
{scenarioData.map((scenario) => (
<th key={scenario.name}>{scenario.name}</th>
))}
</tr>
</thead>
<tbody>
{renderRow("Purchase Price", "purchasePrice", false, "dollar")}
{renderRow("Down Payment", "downPayment", true, "percent", "downPaymentAmount")}
{renderRow("Loan Amount", "loanAmount", true)}
{renderRow("Closing Costs", "closingCosts", true, "percent", "closingCostsAmount")}
{renderRow("Total Down Payment + Closing Costs", "totalDownPaymentClosingCosts", true)}
{renderRow("")}
{renderRow("Interest Rate", "interestRate", false, "percent")}
{renderRow("Loan Duration (Years)", "loanDuration", false, "number")}
{renderRow("Monthly Loan Payment", "monthlyLoanPayment", true)}
{renderRow("")}
{renderRow("Property Income:")}
{renderRow("Rental Revenue", "rentalRevenue", false)}
{renderRow("")}
{renderRow("Expenses:")}
{renderRow("Utilities", "utilities", false, "dollar")}
{renderRow("Property Taxes", "propertyTaxes", true, "percent", "propertyTaxesAmount")}
{renderRow("Home Insurance", "homeInsurance", true, "percent", "homeInsuranceAmount")}
{renderRow("Monthly Loan Payment", "monthlyLoanPayment", true)}
{renderRow("Total Monthly Expenses", "totalMonthlyExpenses", true)}
{renderRow("")}
{renderRow("Monthly Net Income:", "monthlyNetIncome", true)}
{renderRow("Annual Net Income:", "annualNetIncome", true)}
</tbody>
</table>
);
};


// Calculate the monthly net income values for each scenario

export default CashFlowModelTable;


